<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"></Topbar>
    <div class="bg-top-bar p-6 flex justify-between items-center">
      <h2 class="font-bold text-xl" v-if="survey">Exports - {{ survey.survey_name }}</h2>
    </div>
    <div class="flex flex-col p-6 flex-1">
      <ResourceTable
          :page="1"
          :variables="{sortDirection: 'DESC', survey_id: id}"
          :columns="columns"
          :query="ResultQuery"
          name="DownloadsTable"
          :disableActions="false"
          :subscription-gql="surveyExportGql"
          queryKey="listSurveyExports"
      >
        <template v-slot:default="slotProps">
          <button
              type="button"
              :disabled="isLoading[slotProps.item.export_id]"
              v-if="slotProps.item.status === 'downloadable'"
              @click="onDownload(slotProps.item)"
              class="mx-4 px-2 py-2 text-left bg-purple-800 text-white"
          >
            {{ isLoading[slotProps.item.export_id] ? 'Downloading...' : 'Download' }}
          </button>
        </template>
      </ResourceTable>
    </div>
  </div>
</template>

<script>
import {Survey} from '@/models';
import moment from 'moment-timezone';
import upperFirst from 'lodash-es/upperFirst';
import {DataStore} from '@aws-amplify/datastore';
import {listSurveyExports} from '@/graphql/queries';
import ResourceTable from '@/components/ResourceTable';
import {Amplify} from '@aws-amplify/core';
import {onUpdateSurveyExport} from "../../graphql/subscriptions";

const columns = [
  {
    name: 'exported_at',
    text: 'Export Date',
    render: item => {
      return item?.exported_at ? moment(new Date(item?.exported_at)).toString() : '-';
    },
  },
  {
    name: 'status',
    text: 'Status',
    render: item => upperFirst(item?.status),
  },
];

export default {
  data() {
    return {
      survey: null,
      isLoading: [],
    };
  },
  components: {
    ResourceTable,
  },
  computed: {
    surveyExportGql() {
      return onUpdateSurveyExport;
    },
    SurveyResultArchiveFilter() {
      return item => item?.survey_id('eq', this.id);
    },
    id() {
      return this.$route.params.id;
    },
    ResultQuery() {
      return listSurveyExports;
    },
    columns() {
      return columns;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: false,
        },
        {
          name: this.survey ? this.survey.survey_name : 'Surveys View',
          to: {name: 'surveys.results', params: {id: this.id}},
          current: false,
        },
        {
          name: 'Downloads',
          to: {name: 'surveys.downloads', params: {id: this.id}},
          current: true,
        },
      ];
    },
  },
  methods: {
    async loadSurvey() {
      this.survey = await DataStore.query(Survey, this.id);
    },
    async onDownload(item) {
      this.isLoading[item.export_id] = true;
      await Amplify.API.get('exportDownload', `/export/${item.export_id}`)
          .then(response => {
            if (response.success) {
              const a = document.createElement('a');
              a.href = response.url;
              a.download = name;
              a.click();
              a.remove();
            }
            this.isLoading[item.export_id] = false;
          })
          .catch(() => {
            this.isLoading[item.export_id] = false;
          });
    },
  },

  mounted() {
    this.loadSurvey();
  },
};
</script>
